import React, {useEffect, useState} from "react";
import {Card, IconButton, Select, TextField} from "@nike/eds";
import {SearchForm} from "../../../common/model/SearchForm";
import useSessionStorage from "../../../common/hooks/useSessionStorage";
import useAuditFieldListDistinctValues from "../../../common/hooks/useAuditFieldListDistinctValues";

const locationOptions = [
    {value: "TRANSIT", label: "TRANSIT"},
    {value: "ELC", label: "ELC"},
];

const customsStatusOptions = [
    {value: "BONDED", label: "Bonded"},
    {value: "NON_BONDED", label: "Non-Bonded"},
];

const typeOptions = [
    {value: "WAREHOUSE_ENTRY", label: "Warehouse Entry"},
    {value: "DC_GOODS_HOLDER_RECEIPT,DC_DELIVERY_RECEIPT,DRS_DECON_RECEIPT", label: "Goods Receipt"},
    {value: "DC_DELIVERY_REMOVAL", label: "Warehouse Removal"},
    {value: "NODE_ADJUSTMENT_NEGATIVE", label: "Negative Adjustment"},
    {value: "NODE_ADJUSTMENT_POSITIVE", label: "Positive Adjustment"},
    {value: "NODE_ADJUSTMENT_UPDATE", label: "Update Adjustment"},
];

const findOptionByValue = (value, options) => {
    for (const e of options) {
        if (e.value === value) {
            return e;
        }
    }
    return null;
}

const emptySearchCriteria = () => {
    return {
        startDate: '',
        endDate: '',
        location: '',
        customsStatus: '',
        type: '',
        reason: '',
        source: ''
    }
};

const emptyValidation = () => {
    return {
        hasErrors: false,
        startDate: '',
        endDate: ''
    };
};

const arrayToOptions = (options) => {
    if (options && options.map) {
        return options.map(e => {return {value: e, label: e}; });
    }
    return [];
};

function TransactionHistorySearchForm(props) {
    const reasonCodes = useAuditFieldListDistinctValues("reason");
    const sources = useAuditFieldListDistinctValues("executor");
    const reasonCodeOptions = arrayToOptions(reasonCodes);
    const sourceOptions = arrayToOptions(sources);

    const [ searchCriteriaState, setSearchCriteria ] = useSessionStorage("searchCriteria", emptySearchCriteria());
    const [ validationError, setValidationError ] = useState(emptyValidation());

    const searchCriteria = searchCriteriaState.value;

    const clearSearchCriteria = () => {
        setSearchCriteria(emptySearchCriteria());
    }

    useEffect(() => {
        const errors = emptyValidation();
        if (searchCriteria.startDate !== "" && searchCriteria.endDate !== "" && (searchCriteria.startDate > searchCriteria.endDate)) {
            errors.startDate = "The start date should be before the end date";
            errors.endDate = "The end date should be after the start date";
            errors.hasErrors = true;
        }
        setValidationError(errors);
    }, [searchCriteria.startDate, searchCriteria.endDate]);


    const startDateChangeHandler = (event) => {
        setSearchCriteria({
            ...searchCriteria,
            startDate: event.target.value
        });
    }
    const endDateChangeHandler = (event) => {
        setSearchCriteria({
            ...searchCriteria,
            endDate: event.target.value
        });
    }
    const locationChangeHandler = (event) => {
        setSearchCriteria({
            ...searchCriteria,
            location: event !== null ? event.value : ''
        });
    }
    const customsStatusChangeHandler = (event) => {
        setSearchCriteria({
            ...searchCriteria,
            customsStatus: event !== null ? event.value : ''
        });
    }
    const typeChangeHandler = (event) => {
        setSearchCriteria({
            ...searchCriteria,
            type: event !== null ? event.value : ''
        });
    }
    const reasonChangeHandler = (event) => {
        setSearchCriteria({
            ...searchCriteria,
            reason: event !== null ? event.value : ''
        });
    }
    const sourceChangeHandler = (event) => {
        setSearchCriteria({
            ...searchCriteria,
            source: event !== null ? event.value : ''
        });
    }

    let searchForm: SearchForm = {
        startDate: new Date(searchCriteria.startDate),
        endDate: new Date(searchCriteria.endDate),
        location: searchCriteria.location,
        customsStatus: searchCriteria.customsStatus,
        type: searchCriteria.type,
        reason: searchCriteria.reason,
        source: searchCriteria.source
    }

    const onSearch = props.onSearch;

    const submitHandler = (event) => {
        event.preventDefault();
        if (validationError.hasErrors) {
            return;
        }

        onSearch(searchForm, false);
    }

    /* eslint-disable react-hooks/exhaustive-deps --
     * the other used fields are constantly changing but shouldn't trigger a new run of this effect, only the restore.
     * Disabling it here to prevent a rerender-loop
     */
    useEffect(() => {
        if (searchCriteriaState.restored) {
            if (validationError.hasErrors) {
                return;
            }
    
            onSearch(searchForm, true);
        }
    }, [ searchCriteriaState.restored ]);
    /* eslint-enable react-hooks/exhaustive-deps */

    return (
        <div style={{backgroundColor: "white"}}>
            <form onSubmit={submitHandler}>
                <div className="eds-grid eds-grid--m-cols-2 eds-gap--8 eds-elevation--4">
                    <Card className="eds-grid--m-col-1 eds-grid--m-rows-1 eds-grid--item-align-start eds-spacing--p-4">
                        <Card className="eds-grid--m-row-1 eds-spacing--p-4">
                            <div style={{"display": "flex"}}>
                                <div className="eds-spacing--mr-8" style={{width: 260}}>
                                    <TextField
                                        onChange={startDateChangeHandler}
                                        value={searchCriteria.startDate}
                                        id="id"
                                        type="datetime-local"
                                        hasErrors={validationError.startDate !== ''}
                                        errorMessage={validationError.startDate}
                                        label="Enter a start date"
                                    />
                                </div>
                                <div className="eds-spacing--mr-8" style={{width: 260}}>
                                    <TextField
                                        onChange={endDateChangeHandler}
                                        value={searchCriteria.endDate}
                                        id="id"
                                        type="datetime-local"
                                        hasErrors={validationError.endDate !== ''}
                                        errorMessage={validationError.endDate}
                                        label="Enter an end date"
                                    />
                                </div>
                                <div className="eds-spacing--mr-8" style={{width: 260}}>
                                    <Select onChange={locationChangeHandler}
                                            value={findOptionByValue(searchCriteria.location, locationOptions)}
                                            id="locationSelect"
                                            options={locationOptions}
                                            label="Location"
                                            placeholder="Select location..."
                                            errorMessage="Please select a location"
                                            isClearable={true}
                                    />
                                </div>
                                <div className="eds-spacing--mr-8" style={{width: 260}}>
                                    <Select onChange={customsStatusChangeHandler}
                                            value={findOptionByValue(searchCriteria.customsStatus, customsStatusOptions)}
                                            id="customsStatusSelect"
                                            options={customsStatusOptions}
                                            label="Customs status"
                                            placeholder="Select customs status..."
                                            errorMessage="Please select a customs status"
                                            isClearable={true}
                                    />
                                </div>
                            </div>
                        </Card>
                        <Card className="eds-grid--m-row-1 eds-spacing--p-4">
                            <div style={{"display": "flex"}}>
                                <div className="eds-spacing--mr-8" style={{width: 260}}>
                                    <Select onChange={typeChangeHandler}
                                            value={findOptionByValue(searchCriteria.type, typeOptions)}
                                            id="typeSelect"
                                            options={typeOptions}
                                            label="Type"
                                            placeholder="Select type..."
                                            errorMessage="Please select a type"
                                            isClearable={true}
                                    />
                                </div>
                                <div className="eds-spacing--mr-8" style={{width: 260}}>
                                    <Select onChange={reasonChangeHandler}
                                            value={findOptionByValue(searchCriteria.reason, reasonCodeOptions)}
                                            id="reasonSelect"
                                            options={reasonCodeOptions}
                                            label="Reason"
                                            placeholder="Select reason..."
                                            errorMessage="Please select a reason"
                                            isClearable={true}
                                    />
                                </div>
                                <div className="eds-spacing--mr-8" style={{width: 260}}>
                                    <Select onChange={sourceChangeHandler}
                                            value={findOptionByValue(searchCriteria.source, sourceOptions)}
                                            id="sourceSelect"
                                            options={sourceOptions}
                                            label="Source"
                                            placeholder="Select source..."
                                            errorMessage="Please select source"
                                            isClearable={true}
                                    />
                                </div>
                            </div>
                        </Card>
                    </Card>
                    <Card
                        className="eds-grid--m-col-1 eds-grid--m-row-1 eds-grid--item-align-start eds-grid--item-justify-end eds-spacing--p-12 eds-spacing--mt-40"
                        style={{"display": "flex"}}>
                    <Card className="eds-spacing--p-4">
                            <IconButton
                                variant="primary"
                                type={"submit"}
                                size={"small"}
                                icon="Search"
                                label={""}/>
                        </Card>
                        <Card className="eds-spacing--p-4">
                            <IconButton
                                onClick={clearSearchCriteria}
                                variant="primary"
                                icon="Undo"
                                size={"small"}
                            label={""}/>
                        </Card>
                    </Card>
                </div>
            </form>
        </div>
    );
}

export {TransactionHistorySearchForm}
