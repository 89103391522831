import {useState} from "react";
import {Icon, TableCell} from "@nike/eds";
import {LocationDetail} from "../shared/LocationDetail";
import useNavigationAction from "../../common/hooks/useNavigationAction";

function InventoryOverviewTableRow(props) {

    const [locationDetail, setLocationDetail] = useState(false);

    const showLocationDetail = () => setLocationDetail(!locationDetail)

    const bondedCellStyle = {
        color: props.result.bondedQuantity < 0 ? 'red' : 'black'
    };
    const nonBondedCellStyle = {
        color: props.result.nonBondedQuantity < 0 ? 'red' : 'black'
    };
    const totalCellStyle = {
        color: (props.result.bondedQuantity + props.result.nonBondedQuantity) < 0 ? 'red' : 'black'
    };

    const showPointer = {
        cursor: "pointer"
    }

    const noPadding = {
        padding: "0px 0px 8px 0px"
    }

    const navigate = useNavigationAction();

    return (
        <>
            <tr key={props.result.hashKey}>
                <TableCell style={{...showPointer}} onClick={showLocationDetail}>
                    <Icon name={locationDetail ? "CaretDown" : "CaretRight"} size="m"/>
                </TableCell>
                <TableCell key={props.result.hashKey}>{props.result.material}</TableCell>
                <TableCell>{props.result.size}</TableCell>
                <TableCell>{props.result.countryOfOrigin}</TableCell>
                <TableCell>{props.result.quality}</TableCell>
                <TableCell style={bondedCellStyle}>{props.result.bondedQuantity}</TableCell>
                <TableCell style={nonBondedCellStyle}>{props.result.nonBondedQuantity}</TableCell>
                <TableCell style={totalCellStyle}>{props.result.bondedQuantity + props.result.nonBondedQuantity}</TableCell>
                <TableCell>{props.result.snapshotDiff !== null && props.result.snapshotDiff !== 0 
                                                                        ? <Icon name="AlertCircle" size="m" /> 
                                                                        : undefined }</TableCell>
                <TableCell style={{...showPointer}}
                           onClick={() => navigate('details/{material}/{size}/{coo}/{quality}', {
                                material: props.result.material, 
                                size: props.result.size, 
                                coo: props.result.countryOfOrigin, 
                                quality: props.result.quality})}>
                    <Icon name="Show" size="m"/>
                </TableCell>
            </tr>
            {locationDetail ?
                    <tr className="eds-table-row-shadow">
                        <TableCell style={noPadding} colSpan={9}>
                          <LocationDetail inventoryService={props.inventoryService} sku={props.result} />
                        </TableCell>
                    </tr>
                : undefined
            }
        </>
    );
}

export {InventoryOverviewTableRow};