import {useNavigate} from "react-router-dom";


const useNavigationAction = () => {
    const navigate = useNavigate();
    return (url, params) => {
        let targetEndpoint = url;
        if (params) {
            for (const [key, value] of Object.entries(params)) {
                targetEndpoint = targetEndpoint.replace("{" + key + "}", encodeURIComponent(value));
            }
        }
        navigate(targetEndpoint);
    };
}

export default useNavigationAction;