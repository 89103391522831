import {Button, Card, Text} from "@nike/eds";
import {edsSpace16} from "@nike/eds/dist/tokens";
import React, {useContext, useEffect, useState} from "react";
import {TransactionHistoryTable} from "./transaction-history/TransactionHistoryTable";
import SnackbarContext from "../../providers/snackbar-context";
import {useNavigate, useParams} from "react-router-dom";
import {CaretRight} from "@nike/nike-design-system-icons";
import "./InventoryItemDetail.css";
import {QuantityAdjustmentForm} from "./adjust-quantity/QuantityAdjustmentForm";
import {TransactionHistorySearchForm} from "./transaction-history/TransactionHistorySearchForm";
import {SearchForm} from "../../common/model/SearchForm";
import useSessionStorage from "../../common/hooks/useSessionStorage";
import {LocationDetail} from "../shared/LocationDetail";

function InventoryItemDetail({inventoryService, LocationTracker, host}) {

    const params = useParams();

    const snackbarCtx = useContext(SnackbarContext);
    const [loadingTransactions, setLoadingTransactions] = useState(false);
    const [showQuantityAdjustment, setShowQuantityAdjustment] = useState(false);
    let [transactionHistoryResults, setTransactionHistoryResults] = useState([])
    const [ pagingTokensSession, setPagingTokens ] = useSessionStorage("pagingTokens", []);
    let pagingTokens = pagingTokensSession.value;

    const searchHandler = (searchForm: SearchForm, restore : boolean) => {
        // TODO setState is async > how do we fix this so it works like now without accessing the variable directly
        if (restore) {
            pagingTokens = pagingTokens.slice(0, -1);
        } else {
            pagingTokens = []
        }
        setPagingTokens(pagingTokens);
        fetchData(searchForm, 'down', restore)
    }

    const fetchData = (searchForm: SearchForm, direction: string, restore : boolean = false) => {
        setLoadingTransactions(true);

        if (direction === 'up') {
            // remove next and current tokens from the pagingTokens array to retrieve previous set
            pagingTokens.pop()
            pagingTokens.pop()
        }

        inventoryService
            .listTransactions(params.materialNumber, params.size, params.countryOfOrigin, params.quality, searchForm.startDate,
                searchForm.endDate, searchForm.location, searchForm.customsStatus, searchForm.type, searchForm.reason, searchForm.source)
            .then(result => {
                setTransactionHistoryResults(result.data.transactionsDynamicSearch.items);
                setLoadingTransactions(false);
            })
            .catch(error => {
                snackbarCtx.displayMsg("Failed to load transactions: " + error, "error");
                setLoadingTransactions(false);
            })
    }

    useEffect(() => {
        setLoadingTransactions(true)

        inventoryService
            .listTransactions(params.materialNumber, params.size, params.countryOfOrigin, params.quality)
            .then(result => {
                setTransactionHistoryResults(result.data.transactionsDynamicSearch.items);
                setLoadingTransactions(false);
            })
            .catch(error => {
                snackbarCtx.displayMsg("Failed to load transactions: " + error, "error");
            })

    }, [inventoryService, params.materialNumber, params.size, params.countryOfOrigin, params.quality, snackbarCtx]);

    let navigate = useNavigate();

    const routeChange = () => {
        let path = '/inventory-overview';
        navigate(path);
    }

    const titleStyle = {
        marginBottom: edsSpace16
    }

    const boldStyle = {
        fontWeight: "bold"
    }

    const tableDiv = {
        marginTop: edsSpace16
    };

    const marginBottom2Rem = {
        marginBottom: "2rem"
    }

    const detailViewMargin = {
        marginBottom: "1rem"
    }

    const detailTopRow = {
        marginBottom: "4rem",
        width: "80%"
    }

    const buttonWidth = {
        width: "60%"
    }

    const handleAdjustQuantityClick = () => {
        setShowQuantityAdjustment(true);
    }

    return (
        <div>
            <LocationTracker location="Inventory Overview" sublocation="Detail"
                            material={params.materialNumber} size={params.size} coo={params.countryOfOrigin} />
            <Text style={titleStyle} font={"display-3"} as={"h3"}>ITEM DETAILS</Text>
            <Button style={marginBottom2Rem} variant={"ghost"} onClick={() => routeChange()}>
                Inventory
                <CaretRight size={"s"}/>
            </Button>
            {params &&
                <div style={detailTopRow} className="eds-grid eds-grid--m-cols-3">
                    <div className="eds-grid eds-grid--m-row-1">
                        <div className="eds-grid_item eds-grid_item--m-1-2">
                            <Card>
                                <div style={detailViewMargin} className="eds-grid eds-grid--m-cols-2">
                                    <Text>Material</Text>
                                    <Text style={boldStyle}>{params.materialNumber}</Text>
                                </div>
                                <div style={detailViewMargin} className="eds-grid eds-grid--m-cols-2">
                                    <Text>Size</Text>
                                    <Text style={boldStyle}>{params.size}</Text>
                                </div>
                                <div style={detailViewMargin} className="eds-grid eds-grid--m-cols-2">
                                    <Text>Country Of Origin</Text>
                                    <Text style={boldStyle}>{params.countryOfOrigin}</Text>
                                </div>
                                <div className="eds-grid eds-grid--m-cols-2">
                                    <Text>Quality</Text>
                                    <Text style={boldStyle}>{params.quality}</Text>
                                </div>
                            </Card>
                        </div>

                        <div className="eds-grid_item eds-grid_item--m-1-2">
                            <Button style={buttonWidth} className="eds-grid--m-col-1" variant="primary" type="submit" onClick={handleAdjustQuantityClick}>
                                Adjust Quantity
                            </Button>
                        </div>
                    </div>

                    <div className="eds-grid eds-grid--m-col-2">
                            <LocationDetail inventoryService={inventoryService} sku={{
                                material: params.materialNumber,
                                size: params.size,
                                countryOfOrigin: params.countryOfOrigin,
                                quality: params.quality,
                            }} />
                    </div>
                </div>
            }

            {!showQuantityAdjustment && (
                <div style={tableDiv}>
                    <Text style={titleStyle} font={"title-3"} as={"h3"}>Transaction History</Text>
                    <TransactionHistorySearchForm onSearch={searchHandler}/>
                    <TransactionHistoryTable searchResults={transactionHistoryResults} loading={loadingTransactions}/>
                </div>
            )}

            {showQuantityAdjustment && (
                <div>
                    <QuantityAdjustmentForm inventoryService={inventoryService} onClose={() => setShowQuantityAdjustment(false)} host={host} />
                </div>
            )}

        </div>
    )
}

export {InventoryItemDetail};