import {Skeleton, Table, TableCell, TableHeading} from "@nike/eds";
import {TransactionHistoryTableRow} from './TransactionHistoryTableRow';
import React from "react";

function TransactionHistoryTable(props) {

    const headers = [
        {Header: "Timestamp"},
        {Header: "Business reference"},
        {Header: "Location"},
        {Header: "Bonded"},
        {Header: "Non-Bonded"},
        {Header: "Type"},
        {Header: "Details"},
        {Header: "Source"},
        {Header: "Comment"}
    ];

    return (
        <div className="eds-grid eds-elevation--4">
            <Table >
                <thead>
                <tr>
                    {headers.map(column => (<TableHeading key={column.Header}>{column.Header}</TableHeading>))}
                    <th></th>
                </tr>
                </thead>
                <tbody >
                {props.loading &&
                    <tr>
                        <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                        <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                        <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                        <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                        <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                        <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                        <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                        <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                        <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                        <TableCell><Skeleton height={35} width={"100%"} /></TableCell>
                    </tr>
                }
                {
                    !props.loading && props.searchResults && props.searchResults.map((result) => (
                    <TransactionHistoryTableRow key={result.commandId} result={result}/>
                ))

                }
                </tbody>
            </Table>
        </div>
    )
}

export {TransactionHistoryTable};
