export function mapTransactionType(transactionType : string, action : string): string {
    switch(transactionType) {
        case "DC_DELIVERY_RECEIPT":
        case "DC_GOODS_HOLDER_RECEIPT": {
            return "Goods Receipt"
        }
        case "DC_DELIVERY_REMOVAL": {
            return "Warehouse Removal"
        }
        case "WAREHOUSE_ENTRY": {
            return "Warehouse Entry"
        }
        case "NODE_ADJUSTMENT_POSITIVE":  {
            return "Positive Adjustment";
        }
        case "NODE_ADJUSTMENT_NEGATIVE":  {
            return "Negative Adjustment";
        }
        case "NODE_ADJUSTMENT_UPDATE":  {
            return "Update Adjustment";
        }
        case "NODE_ADJUSTMENT":  {
            return "Adjustment";
        }
        default: {
            return "Undefined " + transactionType
        }
    }
}
